import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import "font-awesome/css/font-awesome.min.css"
import "../components/font-face.css"
import "../components/layout.css"
import WebriQForm from "@webriq/gatsby-webriq-form"
import Seo from "../components/seo"

import { StaticImage } from "gatsby-plugin-image"

const spreedSheets = () => {
  return (
    <section className="split-bg">
      <Seo
        title="6 Alternatives To Spreadsheets And Why You Need One | Quandary Consulting Group"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
      />
      <Container>
        <Row>
          <Col md={`${6} bg-light-blue`}>
            <div className="content-right">
              <Link aria-label="link" to="/">
                <StaticImage
                  placeholder="blurred"
                  className="img-fluid w-25"
                  src="../../static/assets/footer-logo.png"
                  alt="logo"
                  layout="constrained"
                  loading="lazy"
                />
              </Link>
              <h5 className="my-3 font-weight-medium text-white">
                FREE RESOURCE DOWNLOAD
              </h5>
              <h1 className="font-weight-medium text-white my-4">
                6 Alternatives To Spreadsheets And Why You Need One
              </h1>
              <p className="text-white">
                88% of all spreadsheets have "significant" errors in them. In
                the past, the lack of industry-wide standards has largely been
                to blame.
              </p>
              <p className="text-white">
                If you do a bit of googling, you’ll see article after article
                with mentions of corrupted data by way of spreadsheets.
                Over-complicated spreadsheets are not only difficult to read and
                analyze but are prone to data entry errors due to their sheer
                size.
              </p>
              <p className="text-white">
                There’s always a better way. New technologies are
                revolutionizing the way we share and analyze data. In this
                guide, you will learn different ways of automating your reports
                without using over-complicated spreadsheets and manual labor.
              </p>
            </div>
          </Col>
          <Col md={6}>
            <div className="content-left text-secondary">
              <div className="intro mb-4">
                <h3 className="font-weight-medium text-secondary">
                  Download our detailed guide now and find out how to start
                  automating your reporting today.
                </h3>
              </div>
              <p>In this free guide you will learn about:</p>
              <ul className="list-unstyled checked mt-4">
                <li>Quickbase</li>
                <li>Domo</li>
                <li>TrackVia</li>
                <li>Kintone</li>
                <li>Airtable</li>
                <li>Smartsheets</li>
              </ul>
              <div className="contact-form-download mt-5">
                <div className="intro mb-4">
                  <h4 className="mt-0 text-secondary">
                    Download your <b>FREE</b> detailed guide now
                  </h4>
                </div>
                <div className="cms-contact-form-holder">
                  <WebriQForm
                    name="Download Guide Form"
                    className="contact-form"
                    data-form-id="5e42792d68775e574cfece87"
                    data-thankyou-url="/thank-you"
                  >
                    <div className="form-group">
                      <input
                        type="text"
                        name="First Name"
                        size={40}
                        className="form-control"
                        placeholder="First Name"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="Last Name"
                        size={40}
                        className="form-control"
                        placeholder="Last Name"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="Phone Number"
                        size={40}
                        minLength={14}
                        className="form-control"
                        id="phone"
                        placeholder="Phone Number"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        name="Email"
                        size={40}
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <div className="form-group mt-4">
                      <div className="webriq-recaptcha" />
                    </div>

                    <div className="form-group mt-4">
                      <button
                        type="submit"
                        className="btn btn-primary btn-arrow"
                      >
                        SUBMIT
                      </button>
                    </div>
                  </WebriQForm>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default spreedSheets
